@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Saira+Stencil+One&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    background: rgb(196, 215, 241);
    color: rgb(255, 255, 255);
    background-attachment: fixed;
    min-width: 100%;
    min-height: 100%;
  }

  html {
    min-width: 450px;
  }


.w-600 { 
  width: 600px;
}

@media (max-width: 768px) {
  .tasklist {
    width: 475px
  }
  .notes {
    width: 425px
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}


@media (min-width: 768px) {
  .notes {
    width: 550px
  }
}

.custom-font {
  font-family: 'Saira Stencil One', cursive;
}

.custom-font2 {
  font-family: 'Oswald', sans-serif;
}

.w-500 {
  width: 500px;
}

.mr-324px {
  margin-right: 324px;
}

.mr-30 {
  margin-right: 117px;
}

.ml-30 {
  margin-left: 117px;
}

.bg-color1 {
  background-color: rgb(196, 215, 241);
}

.bg-color2 {
  background-color: #616668;
}

.bg-color3 {
  background-color: #ff0000;
}

.bg-color4 {
  background-color: #f1f500;
}

.bg-color5 {
  background-color: #333333;
}

.bg-color6 {
  background-color: #272727;
}

.text-custom1 {
  color: #1c1f2b;
}
.shadow-top {
  box-shadow: 0 -5px 3px -3px #00000030;
}

.shadow-right {
  box-shadow: 5px 0 3px -3px #00000030;
}

.shadow-bottom {
  box-shadow: 0 5px 3px -3px #00000030;
}

.shadow-left {
  box-shadow: -5px 0 3px -3px #00000030;
}
.shadow-trl {
  box-shadow: -5px 0 3px -3px #00000030, 5px 0 3px -3px #00000030, 0 -5px 3px -3px #00000030;
}

.box {
  width: 20px;
  height: 24px;
  display: flex;
}

.text-custom1 {
  color: #1c1f2b;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: rgb(86, 103, 119);
}

.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
}

.text-divider::before,
.text-divider::after {
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}

.table-auto tr:nth-child(even) {
  background-color: rgb(79 70 229/ 10%);
  
}


